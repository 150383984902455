import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import Navbar from '../../common/Navbar.vue'
import { Tag, Icon, Tabs, Tab, Image, Cell } from 'vant'
export default {
    components: {
        Loading: Loading,
        Share: Share,
        Navbar: Navbar,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Image.name]: Image,
        [Cell.name]: Cell
    },
    data () {
        return {
            loadingShow: false,
            orderList: [],
            amount: 0,
            orderCode: '2',
            payOrderCode: '1'
        }
    },
    mounted () {
        var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
        if (token !== '' && token !== null && token !== undefined) {
            this.retrieveOrderList()
        } else {
            this.$router.push({ path: '/mde/bind' })
        }
        this.$refs.share.default('main')
    },
    methods: {
        async retrieveOrderList () {
            this.loadingShow = true
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, current: 1, size: 100 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/inform/order/retrieveUserOrderPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.orderList = res.data
            }
            this.loadingShow = false
        },
        remind () {
            this.$dialog.alert({
                title: '提示',
                message: '已进行催单,请耐心等待'
            })
        },
        async pay (amount, code) {
            this.loadingShow = true
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            this.orderCode = code
            this.amount = amount
            var pd = {
                appid: 'wxa8145a74e5f2b39a',
                amount: this.amount * 100,
                attach: this.taskCode,
                description: '证书培训支付',
                body: '证书培训支付',
                detail: '',
                business: this.code,
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        } else {
                            that.loadingShow = false
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.updateOrderPay()
            } else {
                this.loadingShow = false
            }
        },
        async updateOrderPay () {
            var pd = { orderCode: this.orderCode, payCode: this.payOrderCode, amount: this.amount }
            var that = this
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/order/updateOrderPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.confirm({
                    title: '支付结果',
                    message: '支付成功'
                })
                    .then(() => {
                        that.retrieveOrderList()
                    })
            }
            this.loadingShow = false
        }
    }
}
